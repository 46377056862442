#time-picker-wrapper.material-green {
  .time-picker-header {
    background: #4caf50 !important;
  }
  .time-picker-clock > button.active {
    background: #4caf50 !important;
  }
  .time-picker-clock > .time-picker-clock-arrow {
    background: #4caf50 !important;
    span {
      background: #4caf50 !important;
    }
  }
  .time-picker-clock > .time-picker-clock-origin {
    background: #4caf50 !important;
  }
  .time-picker-footer button {
    color: #4caf50 !important;
  }
}