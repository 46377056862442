.bg-blue, .bg-primary {
  background: #3bb4d2 !important;
}
.color-blue, .color-primary {
  color: #3bb4d2 !important;
}

.bg-green {
  background: rgb(84, 217, 89) !important;
}
.color-green {
  color: rgb(84, 217, 89) !important;
}

.bg-orange {
  background: #ff9800 !important;
}

.color-yellow {
  color: #ff9800 !important;
}

.color-red {
  color: #ff0000 !important;
}
