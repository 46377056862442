@media screen and (max-width: 959px) {
  .dialog-small {
    width: 80vw !important;
    max-width: 80vw !important;
  }

  .dialog-fullscreen {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .dialog-medium,
  .dialog-large {
    width: 80vw !important;
    max-width: 80vw !important;
  }

  .dialog-small {
    width: 40vw !important;
    max-width: 40vw !important;
  }
}

@media screen and (min-width: 1280px) {
  .dialog-small {
    width: 30vw !important;
    max-width: 30vw !important;
  }

  .dialog-medium {
    width: 65vw !important;
    max-width: 65vw !important;
  }

  .dialog-large {
    width: 80vw !important;
    max-width: 80vw !important;
  }

  .dialog-fullscreen.monitor-fullscreen {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
  }
}

.mat-dialog-container {
  .mat-dialog-actions {
    .mat-button {
      text-transform: uppercase;
    }
  }
}

.mat-dialog-close {
  position: absolute !important;
  top: 8px !important;
  right: 20px !important;
  min-width: 0px !important;
  padding: 0px 2px !important;
}

.monitor-fullscreen {
  & .mat-dialog-container {
    padding: 0 !important;
  }
}