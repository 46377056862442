.app-chip-yellow {
  background-color: rgb(253, 133,	9) !important;
  color: #ffffff !important;
}

.app-chip-green {
  background-color: rgb(81, 205, 42) !important;
  color: #ffffff !important;
}

.app-chip-blue, .app-chip-primary {
  background-color: rgb(27, 184, 215) !important;
  color: #ffffff !important;
}

.app-chip-red {
  background-color: #FF0000 !important;
  color: #ffffff !important;
}