.app-fab-yellow {
  background-color: rgb(253, 133,	9) !important;
  color: #ffffff !important;
}

.app-fab-green {
  background-color: rgb(81, 205, 42) !important;
  color: #ffffff !important;
}

.app-fab-blue {
  background-color: rgb(27, 184, 215) !important;
  color: #ffffff !important;
}