// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mmm-web-primary: mat.define-palette(mat.$indigo-palette);
$mmm-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mmm-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$mmm-web-theme: mat.define-light-theme($mmm-web-primary, $mmm-web-accent, $mmm-web-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mmm-web-theme);

/* You can add global styles to this file, and also import other style files */
// @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
// @import url("https://fonts.googleapis.com/css?family=Roboto:500,400,300");
// @import "~ng-pick-datetime/assets/style/picker.min.css";

@import './styles/timepicker';
@import './styles/theme';
@import './styles/helper';
@import './styles/dialog';
@import './styles/chip';
@import './styles/table';
@import './styles/fab';
@import './styles/color';
@import './styles/icon';

@include mat.core();

@include mat.all-component-themes($app-theme);

.dark-theme {
  @include mat.all-component-themes($dark-theme);
}

html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
}

#app-content {
  height: calc(100% - 64px);

  @media screen and (max-width: 599px) {
    height: calc(100% - 56px);
  }

  .main-gap-only-secure {
    padding: 24px;
  }
  @media screen and (min-width: 460px) {
    main {
      padding: 24px;
    }
  }

  @media only screen and (max-width: 1382px) {

      font-size: 25px;

  }

}

.auth {
  #app-content {
    height: 100%;
  }
}

mat-sidenav-container {
  margin: 0;
  width: 100%;
  height: 100%;
}

/* Application styles */
.mat-hint.mat-error {
  color: #f44336 !important;
}

.auth .logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: 260px;

  @media screen and (max-width: 667px) {
    width: 180px;
  }

  @media screen and (max-width: 667px) and (orientation: landscape) {
    margin-top: 50px;
  }
}

.auth mat-card {
  width: 450px;

  @media screen and (max-width: 667px) and (orientation: landscape) {
    margin-bottom: 50px;
  }
}

.mat-fab-bottom-right {
  position: fixed !important;
  bottom: 16px !important;
  right: 16px !important;
  z-index: 100;
}

.mat-paginator-page-size {
  @media screen and (max-width: 667px) and (orientation: portrait) {
    display: none !important;
  }
}

// Todo: move this to src/app/shared/dialog-confirm/dialog-confirm.component.scss
app-dialog-confirm {
  .mat-dialog-content {
    h3 {
      .mat-body-2 {
        font-size: 16px !important;
      }
    }
  }
}

.mat-sidenav {
  .mat-divider {
    border-top-color: rgba(255, 255, 255, 0.12);
  }
}

.list-empty {
  text-align: center;
  color: rgba(0, 0, 0, .4);
  .mat-icon {
    @include mat-icon-size(72px);
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
