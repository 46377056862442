.hidden {
  visibility: hidden !important;
}

.no-margin {
  margin: 0 !important;
}

.margin {
  margin: 16px !important;
}

.margin-top {
  margin-top: 17.5px !important;
}

.margin-left {
  margin-left: 17.5px !important;
}

.margin-right {
  margin-right: 17.5px !important;
}

.margin-bottom {
  margin-bottom: 17.5px !important;
}

.no-padding {
  padding: 0 !important;
}

.padding {
  padding: 16px !important;
}

.padding-top {
  padding-top: 16px !important;
}

.padding-left {
  padding-left: 16px !important;
}

.padding-right {
  padding-right: 16px !important;
}

.padding-bottom {
  padding-bottom: 16px !important;
}

.ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ellipsis.td-cell {
  line-height: 48px;
}
